import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent, TableRow } from '../entities/pdf-content';
import { Client } from '../entities/household';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { JourneyService } from './journey.service';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { TextTemplateService } from './text-template.service';
import { WellnessScore } from '../entities/wellness-score';
import { WellnessScoreQuestions } from '../enums/wellness-score-question';
import { getCustomFieldSelectionSingle } from '../shared/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WellnessScoreService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyClientService: JourneyClientService,
    private textTemplateService: TextTemplateService,
    private journeyService: JourneyService
  ) { }


  private readonly defaultScore = null;

  getWellnessScores(journeyID: string): Observable<WellnessScore[]> {
    return this.http.get<WellnessScore[]>(`/api/wellnessscores/${journeyID}/latest`);
  }

  getQuestionWellnessScores(journeyID: string, questionID: number): Observable<WellnessScore[] | undefined> {
    return this.http.get<WellnessScore[]>(`/api/wellnessscores/${journeyID}/${questionID}/latest`);
  }

  saveWellnessScores(wellnessScores: WellnessScore[]): Observable<WellnessScore[]> {
    return this.http.post<WellnessScore[]>("/api/wellnessscores/", wellnessScores);
  }

  async refresh(): Promise<void> {
    if (!this.householdService.household?.client.customFieldSelections) return;

    const wellbeingScores: WellnessScore[] = [];

    for (const key of Object.keys(WellnessScoreQuestions).filter(x => !isNaN(Number(x)))) {

      wellbeingScores.push(this.createQuestion(Number(key), this.journeyClientService.getNonNullablePrimaryClientID(),
        this.getScoreFromClient(this.householdService.household.client, Number(key))));

      if (this.householdService.household.spouse) {
        wellbeingScores.push(this.createQuestion(Number(key), this.journeyClientService.getNonNullableSpouseClientID(),
          this.getScoreFromClient(this.householdService.household.spouse, Number(key))));
      }
    }

    await firstValueFrom(this.saveWellnessScores(wellbeingScores));
  }

  private getScoreFromClient(client: Client, customFieldId: number): number | null {
    const customFieldSelection = getCustomFieldSelectionSingle(client.customFieldSelections, customFieldId);

    return customFieldSelection ? Number(customFieldSelection.itemName) : this.defaultScore;
  }

  createQuestion(wellnessScoreQuestion: WellnessScoreQuestions, journeyClientId: string, score: number | null): WellnessScore {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      questionID: Number(wellnessScoreQuestion),
      created: moment().utc().toDate(),
      score,
      lastModified: moment().utc().toDate(),
      journeyClientID: journeyClientId
    };
  }

  async getPdfSection(journeyID: string): Promise<PdfSection> {
    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Wellbeing questions",
      content: {
        tableHeaders: [
          {
            name: "Question",
            width: "40%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const answers = await firstValueFrom(this.getWellnessScores(journeyID));

    (pdfSection.content as TableContent).tableRows.push(this.getTableRow(answers.filter(x => x.questionID === Number(WellnessScoreQuestions.FinancialWellness)), "financial-wellbeing"));
    (pdfSection.content as TableContent).tableRows.push(this.getTableRow(answers.filter(x => x.questionID === Number(WellnessScoreQuestions.PhysicalWellness)), "physical-wellbeing"));
    (pdfSection.content as TableContent).tableRows.push(this.getTableRow(answers.filter(x => x.questionID === Number(WellnessScoreQuestions.MentalWellness)), "mental-wellbeing"));

    return pdfSection;
  }

  private getTableRow(answers: WellnessScore[], selector: string): TableRow {
    const tableRow: TableRow = {
      columns: [
        {
          content: this.textTemplateService.getSectionTitle(selector)
        },
        {
          content: answers.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID())?.score ?
            String(answers.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID())?.score) : "1"
        }]
    }

    if (this.journeyClientService.spouseJourneyClient()) {
      tableRow.columns.push({
        content: answers.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID)?.score ?
          String(answers.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID)?.score) : "1"
      });
    }

    return tableRow;
  }
}
